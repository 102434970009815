<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-06 09:19:25
 * @LastEditTime: 2023-07-06 11:00
 * @Descripttion: 资源详情
-->
<style lang="scss" scoped>
.courseware {
    &-detail {
        @include innerPage(40px 0 48px);
        @include pageTitle(34px);
    }

    &-inner {
        width: 100%;
        height: calc(100% - 72px);
        box-sizing: border-box;
        padding-left: 12px;
        padding-right: 34px;
        margin-top: 34px;
        overflow: hidden;
        overflow-y: auto;
    }

    &-info {
        &--box {
            width: 100%;
            background: #f6f7fc;
            border-radius: 10px;
            box-sizing: border-box;
            padding: 34px 40px 48px 48px;

            h3 {
                line-height: 38px;
                color: #232323;
            }

            .desc {
                width: calc(100% - 30px);
                margin: 8px 0;
                line-height: 26px;
                color: #4a4a4a;
            }

            .other-info {
                height: 24px;
                font-size: 16px;
                color: #696969;
                margin-top: 8px;
                @include flexBox;

                .time {
                    margin: 0 24px 0 10px;
                }

                .iconfont {
                    font-size: 20px;
                }
            }

            .user-info {
                margin-top: 16px;
                @include flexBox(space-between);

                .uploader {
                    @include flexBox;

                    .el-avatar {
                        border-radius: 50%;
                        border: solid 1px #ececee;
                        margin-right: 8px;
                    }

                    .pf_medium {
                        font-size: 16px;
                        color: #404040;
                    }
                }
            }
        }

        &--medium {
            width: 74%;
            margin: 30px auto 0;

            img {
                width: 100%;
                height: auto;
                border-radius: 30px;
            }
        }
    }
}

.audit {
    &-box {
        box-sizing: border-box;
        padding: 50px 0 0 0;
        margin-top: 50px;
        border-top: 1px solid #e1e2eb;

    }

    &-result {
        width: calc(100% - 20px);
        margin: 30px auto 0;

        &--inner {
            width: 100%;
            margin-bottom: 50px;
            background: #f6f7fc;
            border-radius: 10px;
            box-sizing: border-box;
            padding: 20px 28px;

            p {
                width: 760px;
                min-height: 46px;
                box-sizing: border-box;
                padding: 11px 0;
                font-size: 16px;
                color: #484848;
                @include flexBox(flex-start, flex-start);

                span {
                    display: inline-block;
                    line-height: 24px;

                    &.label {
                        width: 84px;
                        padding-right: 20px;
                        text-align: right;
                        box-sizing: border-box;
                    }

                    &.failed {
                        color: #aeaeae;
                    }

                    &.primary {
                        color: #f8c436;
                    }

                    &.success {
                        color: #3bc8af;
                    }
                }
            }
        }
    }

    &-form {
        width: 760px;
        box-sizing: border-box;
        padding-top: 30px;
        padding-left: 20px;

        .buttons {
            margin-top: 60px;
        }
    }
}
</style>

<template>
    <section class="courseware-detail">
        <div class="page-title">
            <h3>资源详情</h3>
            <el-button type="custom_primary" size="small" @click="$router.go(-1)">
                返回
            </el-button>
        </div>
        <div class="courseware-inner">
            <div class="courseware-info--box">
                <h3 class="bold">{{ detailData[`${keyPrefix}title`] }}</h3>
                <p class="desc light">
                    {{ detailData[`${keyPrefix}remark`] }}
                </p>
                <p class="other-info pf_medium">
                    <span class="iconfont">&#xe61a;</span>
                    <span class="time">
                        {{ detailData.create_time | formatTime('YYYY/MM/DD') }}</span>
                    <span>
                        {{ detailData[`${keyPrefix}grade_name`] || detailData[`${keyPrefix}grade`] }}
                    </span>
                </p>
                <div class="user-info">
                    <p class="uploader">
                        <el-avatar :size="62" :src="formatfile(detailData.teuse_image)">
                            <img src="@assets/images/empty_avatar.png" />
                        </el-avatar>
                        <span class="pf_medium">{{ detailData.teuser_name }}</span>
                    </p>
                    <el-button type="custom_primary" size="small" :disabled="btnLoad"
                        @click="downloadFile(detailData[`${keyPrefix}url`])" v-loading="btnLoad">
                        下载
                    </el-button>
                </div>
            </div>
            <div class="courseware-info--medium" v-if="mediumType(detailData[`${keyPrefix}type`])">
                <template v-if="mediumType(detailData[`${keyPrefix}type`]) == 'image'">
                    <img :src="formatfile(detailData[`${keyPrefix}url`])" alt="">
                </template>
                <template v-if="mediumType(detailData[`${keyPrefix}type`]) == 'video'">
                    <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
                        :options="playerOptions" />
                </template>
            </div>
            <!-- 普通详情审核信息 -->
            <div class="audit-box" v-if="$route.params.type == 'my' && detailData[`${keyPrefix}sharestatus`] > 10">
                <div class="page-title">
                    <h3>审核信息</h3>
                </div>
                <div class="audit-result">
                    <div class="audit-result--inner">
                        <p>
                            <span class="label">审核</span>
                            <span class="value" :class="auditClass">
                                {{ auditText }}
                            </span>
                        </p>
                        <template v-if="detailData[`${keyPrefix}sharestatus`] > 20">
                            <p>
                                <span class="label">审核意见</span>
                                <span class="value">{{ detailData[`${keyPrefix}reason`] }}</span>
                            </p>
                            <p>
                                <span class="label">审核人</span>
                                <span class="value">{{ detailData[`${keyPrefix}exa_name`] || '-' }}</span>
                            </p>
                            <p>
                                <span class="label">审核时间</span>
                                <span class="value">
                                    {{ detailData[`${keyPrefix}exa_time`] | formatTime('YYYY/MM/DD HH: mm') }}
                                </span>
                            </p>
                        </template>
                    </div>
                    <el-button type="custom_primary" size="small" v-if="detailData[`${keyPrefix}sharestatus`] == 40"
                        @click.stop="shareFile">
                        重新共享
                    </el-button>
                </div>
            </div>
            <!-- 审核 -->
            <div class="audit-box" v-if="$route.params.audit">
                <div class="page-title">
                    <h3>{{ $route.params.audit == 1 && '审核信息' || '平台审核信息' }}</h3>
                </div>
                <div class="audit-result" v-if="$route.params.audit != 1 || detailData[`${keyPrefix}sharestatus`] > 20">
                    <div class="audit-result--inner">
                        <p>
                            <span class="label">审核</span>
                            <span class="value" :class="auditClass">
                                {{ auditText }}
                            </span>
                        </p>
                        <template v-if="detailData[`${keyPrefix}sharestatus`] > 20">
                            <p>
                                <span class="label">审核意见</span>
                                <span class="value">{{ detailData[`${keyPrefix}reason`] }}</span>
                            </p>
                            <p>
                                <span class="label">审核人</span>
                                <span class="value">{{ detailData[`${keyPrefix}name`] || '-' }}</span>
                            </p>
                            <p>
                                <span class="label">审核时间</span>
                                <span class="value">
                                    {{ detailData[`${keyPrefix}time`] | formatTime('YYYY/MM/DD HH: mm') }}
                                </span>
                            </p>
                        </template>
                    </div>
                </div>
                <el-form ref="auditForm" :model="auditForm" label-width="84px" class="audit-form" :rules="auditRules"
                    v-if="detailData[`${keyPrefix}sharestatus`] == 20 && $route.params.audit == 1">
                    <el-form-item label="审核：">
                        <el-radio-group v-model="auditForm.sccou_exa_type">
                            <el-radio :label="1">通过</el-radio>
                            <el-radio :label="2">驳回</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="审核意见：" prop="sccou_reason">
                        <el-input v-model.trim="auditForm.sccou_reason" type="textarea" maxlength="200"
                            placeholder="请输入审核意见" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="custom_info" size="small" @click="$router.go(-1)">
                            取 消
                        </el-button>
                        <el-button type="custom_primary" size="small" :disabled="submitLoad" @click="submitAduitResult"
                            v-loading="submitLoad">
                            确 定
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </section>
</template>

<script>
import { $coursewareDetail, $shareCourseware } from "@api/resources"
import { $auditCoursewareDetail, $shareCoursewareDetail, $auditCourseware } from "@api/audit"
import { $downloadFile } from "@api/common"
import { formatFile, formatFileType } from "@utils"
export default {
    name: 'coursewareDetail',
    computed: {
        fileType() {
            return function (val) {
                return formatFileType(val);
            }
        },
        formatfile() {
            return function (url) {
                return formatFile(url)
            }
        },
        mediumType() {
            return function (val) {
                const videoType = ["mp4", "avi", "wma", "rmvb", "rm", "flash", "mid", "3gp"];
                const imgType = ["jpg", "jpeg", "png", "svg", "gif", "tif", "bmp", "webp"];
                if (videoType.indexOf(val) == -1 && imgType.indexOf(val) == -1) return false;
                else if (videoType.indexOf(val) != -1) return 'video';
                else if (imgType.indexOf(val) != -1) return 'image'
            }
        },
        auditClass() {
            let status = this.detailData[`${this.keyPrefix}sharestatus`];
            let className = '';
            switch (status) {
                case 20:
                    className = "primary"
                    break;
                case 30:
                    className = "success"
                    break;
                case 40:
                    className = "failed"
                    break;
                default:
                    break;
            }
            return className;
        },
        auditText() {
            let status = this.detailData[`${this.keyPrefix}sharestatus`];
            let text = '';
            switch (status) {
                case 20:
                    text = "待审核"
                    break;
                case 30:
                    text = "已通过"
                    break;
                case 40:
                    text = "已驳回"
                    break;
                default:
                    break;
            }
            return text;
        }
    },
    data() {
        let validateReason = (rule, value, callback) => {
            let { sccou_exa_type, sccou_reason } = this.auditForm
            if (sccou_exa_type == 2 && !sccou_reason) {
                callback(new Error("请填写审核意见"));
            } else {
                callback();
            }
        };
        return {
            detailData: {},
            keyPrefix: 'sccou_',
            auditForm: {
                sccou_exa_type: 1
            },
            auditRules: {
                sccou_reason: [
                    { required: true, validator: validateReason, trigger: "blur" },
                ],
            },
            btnLoad: false,
            submitLoad: false,
            /* 视频播放器配置 */
            playerOptions: {
                playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                autoplay: false, // 如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [],
                poster: '', // 视频封面
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controls: true,
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true // 全屏按钮
                }
            },
        }
    },
    created() {
        let { audit } = this.$route.params;
        if (audit) this.getAuditDetail();
        else this.getDetail();
    },
    methods: {
        /** 获取课件资源详情 */
        async getDetail() {
            let { type, id } = this.$route.params;
            this.keyPrefix = type == 1 && 'sccou_' || type == 2 && 'plcou_'
            let { data } = await $coursewareDetail(type, id);
            this.detailData = data;
            if (this.mediumType(data[`${this.keyPrefix}type`]) == 'video') {
                this.playerOptions = {
                    ...this.playerOptions,
                    sources: [{
                        type: 'video/mp4',
                        src: this.formatfile(data[`${this.keyPrefix}url`])
                    }]
                };
            }
            this.$forceUpdate();
        },
        /** 获取审核课件资源详情 */
        async getAuditDetail() {
            let { audit, id } = this.$route.params;
            this.keyPrefix = audit == 1 && 'sccou_exa_' || audit == 2 && 'plcou_exa_'
            let res;
            if (audit == 1) res = await $auditCoursewareDetail(id);
            if (audit == 2) res = await $shareCoursewareDetail(id);
            let { data } = res;
            this.detailData = data;
            if (this.mediumType(data[`${this.keyPrefix}type`]) == 'video') {
                this.playerOptions = {
                    ...this.playerOptions,
                    sources: [{
                        type: 'video/mp4',
                        src: this.formatfile(data[`${this.keyPrefix}url`])
                    }]
                };
            }
            this.$forceUpdate();
        },
        /** 提交审核结果 */
        submitAduitResult() {
            this.$refs.auditForm.validate(async (valid) => {
                if (valid) {
                    this.submitLoad = true;
                    let params = {
                        ...this.auditForm,
                        sccou_exa_id: this.$route.params.id
                    }
                    let res = await $auditCourseware(params);
                    this.submitLoad = false;
                    if (res) {
                        this.$message({
                            type: "success",
                            duration: 1500,
                            message: "审核结果提交成功",
                        });
                        this.$refs.auditForm.resetFields();
                        this.auditForm = { sccou_exa_type: 1 };
                        this.getAuditDetail();
                    }
                }
            });
        },
        /** 重新共享 */
        shareFile() {
            let id = this.$route.params;
            this.$msgbox({
                title: "提示",
                message: "确认将此课件资源重新共享至平台？",
                type: "info",
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: "el-button--custom_info",
                confirmButtonClass: "el-button--custom_primary",
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(async () => {
                let res = await $shareCourseware(id);
                if (res) {
                    this.$message({
                        type: "success",
                        duration: 1500,
                        message: "申请已提交，等待平台审核",
                    });
                    this.getDetail();
                }
            }).catch(() => {
                this.$message({
                    type: "info",
                    duration: 1500,
                    message: "操作已取消",
                });
            });
        },
        /** 文件下载 */
        async downloadFile(fileUrl) {
            this.btnLoad = true;
            let { data } = await $downloadFile(fileUrl);
            this.btnLoad = false;
            if (data.url) this.$fileDownload(data.url)
        }
    },
}
</script>